import React from 'react';
import {TextInput} from 'react-admin'
import PropTypes from "prop-types";

export const FormattedNumberInput = (props) => {
    const {label, source, name, onChange, disabled, fullWidth, required} = props;
    const nf = new Intl.NumberFormat("en-GB");

    const onChangeAction = (event) => {
        if (onChange) {
            onChange(event);
        }
    }

    const parse = (value) => {
        return value === '' || value === '0' ? null : Number((value.match(/[\d.-]/g,"") || []).join(""))
    }

    const format = (value) => {
        return value === null || value === undefined ? '' : nf.format(value);
    }

    return (
        <TextInput label={label} source={source} required={!!required}
                   onChange={onChangeAction} disabled={!!disabled} fullWidth={!!fullWidth}
                   autoComplete="off" name={name}
                   format={format}
                   parse={parse}
        />
    );
}

FormattedNumberInput.propTypes = {
    label: PropTypes.string || PropTypes.bool,
    onChange: PropTypes.func,
    source: PropTypes.string.isRequired,
    name: PropTypes.string,
    required: PropTypes.bool,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
}
