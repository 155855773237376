import React, {useState} from "react";
import {
    Button,
    Create,
    Datagrid,
    DateField,
    Edit,
    EditButton, FormDataConsumer,
    List,
    NumberInput,
    RecordContextProvider,
    Show,
    ShowButton,
    SimpleForm,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
    TopToolbar,
    useDataProvider,
    useNotify,
    useRefresh,
    useShowController,
} from 'react-admin';
import Resources from "../Resources";
import DictField from "./controls/DictField";
import DictInput from "./controls/DictInput";
import {PartyField} from "./controls/PartyField";
import {PARTY_TYPE} from "../common/constants";
import LoanApplicationForLegalEntities from "./entityDetails/LoanApplicationForLegalEntities";
import LoanApplicationForHNW from "./entityDetails/LoanApplicationForHNW";
import DateTimeFieldTZ from "./controls/DateFieldTZ";

import ActionButton from "./controls/ActionButton";
import FormattedAmountField from "./controls/FormattedAmountField";
import AuthenticatedBulkDeleteAction from "./controls/AuthenticatedBulkDeleteAction";
import LoanApplicationDetails from "./entityDetails/LoanApplicationDetails";
import {CurrencyInput} from "./controls/CurrencyInput";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {CapitalSourcingDialog} from "./loan_applications/CapitalSourcingDialog";
import RejectLoanApplicationDialog from "./entityDetails/RejectLoanApplicationDialog";
import LoanApplicationCommonDataEditForm from "./loan_applications/LoanApplicationCommonDataEditForm";
import {LoanApplicationStatus} from "./dicts/LoanApplicationEnum";
import FundLoanApplicationDialog from "./loan_applications/FundLoanApplicationDialog";
import EnumDictField from "./controls/EnumDictField";
import EnumDictInput from "./controls/EnumDictInput";
import LegalEntityInput from "./controls/LegalEntityInput";
import CountryInput from "./controls/CountryInput";
import UserLoginInput from "./controls/UserLoginInput";
import {CurrencyType} from "./dicts/ExchangeEnums";
import {useFormContext} from "react-hook-form";
import {InactivationDialog} from "./loan_applications/InactivationDialog";
import {FormattedNumberInput} from "./controls/FormattedNumberInput";

const listFilters = [
    <DictInput source="loanTypeCode" label="Loan Type" reference={Resources.DICT_LOAN_TYPES.name}
               alwaysOn resettable sx={{width: "210px"}}/>,
    <EnumDictInput enum={LoanApplicationStatus} label="Application Status" source="statusCode"
                   alwaysOn resettable sx={{width: "210px"}}/>,
    <TextInput source="fullCompanyName" label="Organisation" resettable alwaysOn />,
];
export const LoanApplicationList = () => (
    <List sort={{field: "id", order: "DESC"}} filters={listFilters}>
        <Datagrid rowClick={false} data-testid="loan-apps-list-grid" bulkActionButtons={<AuthenticatedBulkDeleteAction/>}>
            <TextField source="id"/>
            <PartyField source="partyId" label="Customer"/>
            {/*<DateField source="createdDate" showTime/>*/}
            <DateTimeFieldTZ source="createdDate" label={"Created Date (London)"}/>
            <DictField source="loanTypeCode" label="Loan Type" reference={Resources.DICT_LOAN_TYPES.name}/>
            <EnumDictField source="statusCode" enum={LoanApplicationStatus} />
            <FormattedAmountField
                label="Loan Amount"
                source="loanAmount"
                currencyAttr="currency"
                options={{maximumFractionDigits: 0}}
            />
            <TextField source="loanTerm"/>
            <TextField source="initiatorLogin"/>
            {/*<TextField source="collateralCurrencyCode" />*/}
            {/*<TextField source="collateralCurrencyOther" />*/}
            {/*<TextField source="creditInterest" />*/}
            {/*<TextField source="body" />*/}
            {/*<BooleanField source="isBorrowerConfirmationRequired"
                          label="Needs Borrower Confirm?"/>*/}
            <EditButton/>
            <ShowButton data-testid="loan-app-details-link" />
        </Datagrid>
    </List>
)


export const LoanApplicationEdit = () => {
    // const loanTypesHook = useGetList(Resources.DICT_LOAN_TYPES.name);
    // const loanTypes = loanTypesHook.data;
    // console.log("loanTypes", loanTypes)
    return (
        <Edit>
            <SimpleForm>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <Typography fontSize="12px">Created Date</Typography>
                        <DateField source="createdDate" showTime/>
                    </Grid>
                    <Grid item xs={2}>
                        <DictInput label="Loan Type" source="loanTypeCode"
                                   reference={Resources.DICT_LOAN_TYPES.name}
                                   fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <NumberInput source="loanTerm" fullWidth/>
                    </Grid>
                    <Grid item xs={5}/>
                    <Grid item xs={2}>
                        <FormattedNumberInput label="Loan Amount" source="loanAmount" fullWidth/>
                    </Grid>
                    <Grid item xs={4}>
                        <CurrencyInput label="Currency" source="currency"
                                       reference={Resources.DICT_CURRENCIES.name}
                                       fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}/>
                    <Grid item xs={2}>
                        <TextInput source="creditInterest" fullWidth/>
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput source="additionalInfo" fullWidth/>
                    </Grid>
                    <Grid item xs={5}/>
                    <Grid item xs={2}>
                        <TextInput source="collateralAmount" fullWidth/>
                    </Grid>
                    <Grid item xs={4}>
                        <CurrencyInput label="Collateral Currency" source="collateralCurrencyCode"
                                   reference={Resources.DICT_CURRENCIES.name}
                                   fullWidth
                        />
                    </Grid>
                    <Grid item xs={5}/>
                    <Grid item xs={2}>
                        <TextInput source="initiatorLogin" fullWidth disabled/>
                    </Grid>
                    <Grid item xs={2}>
                        <EnumDictInput enum={LoanApplicationStatus} label="Application Status" source="statusCode" fullWidth />
{/*
                        <DictInput label="Application Status" source="statusCode"
                                   reference={Resources.DICT_LOAN_APPLICATION_STATUSES.name}
                                   fullWidth
                        />
*/}
                    </Grid>
                    <LoanApplicationCommonDataEditForm />
                </Grid>
            </SimpleForm>
        </Edit>
    )
};

const CreateLoanApplicationForm = (props) => {
    const {setValue} = useFormContext();
    const [disableCountry, setDisableCountry] = useState(false)
    const onLegalEntityChange = (le, res) => {
        let country = res?.registeredOfficeAddress?.country
        setValue("rawdata.country", country)
        setDisableCountry(!!country)
    }

    return <>
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <DictInput label="Loan Type" source="loanTypeCode"
                           reference={Resources.DICT_LOAN_TYPES.name}
                           fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <NumberInput source="loanTerm" fullWidth label="Loan term (in months)"/>
            </Grid>
            <Grid item xs={2}>
                <TextInput source="loanAmount" fullWidth/>
            </Grid>
            <Grid item xs={2}>
                <CurrencyInput label="Currency" source="currency"
                               reference={Resources.DICT_CURRENCIES.name}
                               fullWidth
                               currencyTypeCode={CurrencyType.FIAT.code}
                />
            </Grid>
            <Grid item xs={2}/>
            <Grid item xs={3}>
                <LegalEntityInput source="partyId" fullWidth label="Borrower" onChange={onLegalEntityChange}/>
            </Grid>
            <Grid item xs={2}>
                <CountryInput source="rawdata.country" fullWidth label="Country" required disabled={disableCountry}/>
            </Grid>
            <Grid item xs={4}>
                <UserLoginInput source="initiatorId" fullWidth />
            </Grid>
            <Grid item xs={2}/>
            <Grid item xs={7}>
                <TextInput source="additionalInfo" fullWidth/>
            </Grid>
            <Grid item xs={2}>
                <EnumDictInput enum={LoanApplicationStatus} label="Application Status" source="statusCode" fullWidth disabled/>
            </Grid>
            <LoanApplicationCommonDataEditForm disableBorrowerConfirmIfInitiatorIsEmpty/>
        </Grid>
    </>
}

export const LoanApplicationCreate = () => {
    return (
        <Create>
            <SimpleForm defaultValues={{statusCode: LoanApplicationStatus.SUBMITTED.code}}>
                <FormDataConsumer>
                    {({formData, ...rest}) => (
                        <CreateLoanApplicationForm rest/>
                    )}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    )
};

const LoanApplicationsShowActions = (props) => {
    const record = props.record;
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const [submitting, setSubmitting] = useState(false);
    const [capitalSourcingDialogOpened, setCapitalSourcingDialogOpened] = useState(false);
    const [inactivationDialogOpened, setInactivationDialogOpened] = useState(false);
    const [fundingDialogOpened, setFundingDialogOpened] = useState(false);

    const openCapitalSourcingDialog = () => {
        setCapitalSourcingDialogOpened(true);
    }

    const closeCapitalSourcingDialog = () => {
        setCapitalSourcingDialogOpened(false);
    }

    const openInactivationDialog = () => {
        setInactivationDialogOpened(true);
    }

    const closeInactivationDialog = () => {
        setInactivationDialogOpened(false);
    }

    const openFundingDialog = () => {
        setFundingDialogOpened(true);
    }

    const closeFundingDialog = () => {
        setFundingDialogOpened(false);
    }

    const onCapitalSourcingSubmit = (data) => {
        setSubmitting(true);
        // console.log('onCapitalSourcingSubmit', data)
        dataProvider['doUpload'](Resources.LOAN_APPLICATIONS.name, {
            action: Resources.LOAN_APPLICATIONS.actions.CAPITAL_SOURCING.name,
            id: record.id,
            method: 'PUT',
            body: data,
        }).then(({data}) => {
            if (data.success) {
                onCapitalSourcingSuccess(data);
            } else {
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg}, notify)
            }
        }).catch(error => onError(error, notify));
    };

    const onCapitalSourcingSuccess = (data) => {
        // console.log("capitalSourcing.onSuccess", data)
        setCapitalSourcingDialogOpened(false);
        setSubmitting(false);
        notify(
            'Loan application is on Capital Sourcing stage',
            {type: 'success', autoHideDuration: 2000}
        );
        refresh();
    };

    const onError = (error) => {
        // console.log("onError", error)
        // failure side effects go here
        let msg = ""
        if (error && error.response && error.response.data && error.response.data.success === false) {
            msg = error.response.data.errMessage
        } else {
            msg = error.message
        }
        notify(`(${error.code}) ${msg}`, {type: 'warning'});
        setSubmitting(false);
    };
    const [rejectDialogOpened, setRejectDialogOpened] = useState(false);

    const openRejectDialog = () => {
        setRejectDialogOpened(true)
    }

    const closeRejectDialog = () => {
        setRejectDialogOpened(false)
    }

    const onSuccessRejection = (loan) => {
        notify('Loan application has been rejected.', {type: 'success', autoHideDuration: 2000});
        refresh();
        closeRejectDialog();
    }
    const rejectLoanApplication = (data) => {
        data.loanId = record.id;
        setSubmitting(true);
        // console.log("data", data)

        dataProvider['doBodyAction'](Resources.LOAN_APPLICATIONS.name, {
            action: Resources.LOAN_APPLICATIONS.actions.REJECT.name,
            id: record.id,
            method: 'PUT',
            body: data,
        }).then(({data}) => {
            // console.log(data)
            if (data.success) {
                onSuccessRejection(data);
            } else {
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg})
            }
        }).catch(error => {
            onError(error);
        });
    };

    return record && <TopToolbar>
        <EditButton/>
        {record.statusCode === LoanApplicationStatus.SUBMITTED.code &&
            <ActionButton color="primary" label="Analyse" action="process" record={record} method="PUT"
                          resource={Resources.LOAN_APPLICATIONS.name}/>}
{/*
        {record.statusCode === 'PROCESSING' &&
            <ActionButton color="primary" label="Approve" action="approve" record={record} method="PUT"
                          resource={Resources.LOAN_APPLICATIONS.name}/>}
*/}
        {record.statusCode === LoanApplicationStatus.PROCESSING.code &&
            <Button color="primary" label="Capital Sourcing" onClick={openCapitalSourcingDialog}/>}
        {capitalSourcingDialogOpened && <CapitalSourcingDialog record={record} isLoading={submitting}
                                                               submit={onCapitalSourcingSubmit}
                                                               open={capitalSourcingDialogOpened}
                                                               close={closeCapitalSourcingDialog}/>}
        {(record.statusCode === LoanApplicationStatus.SOURCING_OF_CAPITAL.code) &&
            <Button color="primary" label="Fund" onClick={openFundingDialog}/>}
        {fundingDialogOpened &&
            <FundLoanApplicationDialog close={closeFundingDialog} loanApplication={record} isOpened={fundingDialogOpened} />}
        {(record.statusCode === LoanApplicationStatus.PROCESSING.code || record.statusCode === LoanApplicationStatus.SOURCING_OF_CAPITAL.code) &&
            <Button color="primary" label="Reject" onClick={openRejectDialog}/>}
        {rejectDialogOpened &&
            <RejectLoanApplicationDialog record={record} submit={rejectLoanApplication} isLoading={submitting}
                                   open={rejectDialogOpened} close={closeRejectDialog}/>}
        {(record.statusCode === LoanApplicationStatus.PROCESSING.code
                || record.statusCode === LoanApplicationStatus.SOURCING_OF_CAPITAL.code
                || record.statusCode === LoanApplicationStatus.SUBMITTED.code
                || record.statusCode === LoanApplicationStatus.DRAFT.code
                || record.statusCode === LoanApplicationStatus.APPROVED.code
            ) && <Button color="primary" label="Inactivate" onClick={openInactivationDialog}/>}
        {inactivationDialogOpened && <InactivationDialog record={record} isLoading={submitting}
                                     open={inactivationDialogOpened}
                                     close={closeInactivationDialog}/>}
{/*
            <ActionButton color="primary" label="Inactivate" action="inactivate" record={record} method="PUT"
                              resource={Resources.LOAN_APPLICATIONS.name}/>}*/}
    </TopToolbar>
}

export const LoanApplicationShow = () => {
    const {record} = useShowController();
    if (!record) return null;

    return (
        <RecordContextProvider value={record}>
            <Show actions={<LoanApplicationsShowActions record={record}/>}>
                {record.partyId === undefined
                    && <TabbedShowLayout>
                        <Tab label="Loan info">
                            <LoanApplicationDetails record={record}/>
                        </Tab>
                    </TabbedShowLayout>
                }
                {record.partyType === PARTY_TYPE.LEGAL_ENTITY && <LoanApplicationForLegalEntities record={record}/>}
                {record.partyType === PARTY_TYPE.PERSON && <LoanApplicationForHNW record={record}/>}
            </Show>
        </RecordContextProvider>
    );
}
