import PropTypes from 'prop-types'
import Grid from "@mui/material/Grid";
import {TextInput} from "react-admin";
import CountryInput from "./CountryInput";

function AddressInput(props) {
    return (
        <>
            <Grid item xs={3}>
                <TextInput source={`${props.source}.addressLine1`} fullWidth/>
            </Grid>
            <Grid item xs={3}>
                <TextInput source={`${props.source}.addressLine2`} fullWidth/>
            </Grid>
            <Grid item xs={2}>
                <TextInput source={`${props.source}.city`} fullWidth/>
            </Grid>
            <Grid item xs={2}>
                <TextInput label="State/Province" source={`${props.source}.stateProvince`} fullWidth/>
            </Grid>
            <Grid item xs={3}>
                <CountryInput source={`${props.source}.country`} fullWidth/>
            </Grid>
            <Grid item xs={1}>
                <TextInput label="Postal Code" source={`${props.source}.postalCode`} fullWidth/>
            </Grid>

        </>
    )
}

export default AddressInput;

AddressInput.propTypes = {
  source: PropTypes.string.isRequired
}