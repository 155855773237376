import Grid from "@mui/material/Grid";
import {BooleanInput, DateInput, FunctionField, SimpleForm, TextField, TextInput, usePermissions} from "react-admin";
import * as React from "react";
import CountryInput from "../controls/CountryInput";
import AddressInput from "../controls/AddressInput";
import {AdminPortalRoles} from "../dicts/Security";
import {CheckPermission, haveRole} from "../security/CheckPermission";

function PersonsForm(props) {
    const { permissions } = usePermissions();

    return (
        <SimpleForm>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <TextInput source="firstName" required fullWidth/>
                </Grid>
                <Grid item xs={2}>
                    <TextInput source="lastName" required fullWidth/>
                </Grid>
                <FunctionField render={(record) => (<>
                        {record?.id && <Grid item xs={6}>
                            <TextField source="userLogin" />
                        </Grid>}
                </>)} />
                <Grid item xs={2}>
                    <TextInput source="email" required fullWidth/>
                </Grid>
                <Grid item xs={2}>
                    <BooleanInput label="Email is verified" source="emailVerified" fullWidth
                                  disabled={!haveRole(permissions, AdminPortalRoles.BACKOFFICE_ADMIN)}
                                  className="bool-input"/>
                </Grid>
                <Grid item xs={2}>
                    <BooleanInput label="Monivolt Representative" source="monivoltRepresentative" fullWidth
                                  disabled={!haveRole(permissions, AdminPortalRoles.BACKOFFICE_ADMIN)}
                                  className="bool-input"/>
                </Grid>
                <Grid item xs={3}>
                    <TextInput source="userLogin" fullWidth
                               disabled={!haveRole(permissions, AdminPortalRoles.BACKOFFICE_ADMIN)}/>
                </Grid>
                <Grid item xs={2}>
                    <TextInput source="phoneNumber" fullWidth/>
                </Grid>
                <Grid item xs={3}>
                    <TextInput source="title" fullWidth/>
                </Grid>
                <Grid item xs={2}>
                    <DateInput label="Date of Birth" source="birthDate" fullWidth/>
                </Grid>
                <AddressInput source="registeredAddress" />
                <Grid item xs={2}>
                    <CountryInput label="Residency" source="residency" fullWidth/>
                </Grid>
                <Grid item xs={2}>
                    <CountryInput multipleChoice label="Citizenship" source="citizenship" fullWidth/>
                </Grid>
            </Grid>
        </SimpleForm>
    );
}

export default PersonsForm;