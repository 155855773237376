import {ReferenceField, TextField, useRecordContext, FunctionField} from "react-admin";
import Resources from "../../Resources";
import {PARTY_TYPE} from "../../common/constants";
import PropTypes from "prop-types";
import {PartyInput} from "./PartyInput";
import {getValueByPath} from "../../common/utils";

export const PartyField = (props) => {
    const record = useRecordContext();
    if (!record || !getValueByPath(record, props.source)) return null;

    let partyResourceName;
    let label
    let component
    const partyType = props.partyTypeSource && record[props.partyTypeSource] || props.partyType || record.partyType;
    const partyName = record.partyName;

    switch (partyType) {
        case PARTY_TYPE.LEGAL_ENTITY:
            label = Resources.LEGAL_ENTITY.singleLabel;
            partyResourceName = Resources.LEGAL_ENTITY.name;
            component = <TextField source="fullCompanyName"/>;
            break;
        case PARTY_TYPE.PERSON:
            label = Resources.PERSONS.singleLabel;
            partyResourceName = Resources.PERSONS.name;
            component = <FunctionField render={record => `${record.firstName} ${record.lastName}`}/>;
            break
    }

    return (
        <span>
            {label}:{" "}
            {!!partyName && <a href={"/#/" + partyResourceName + "/" + record[props.source] + "/show"}>{partyName}</a>}
            {!partyName && <ReferenceField label={props.label} source={props.source} reference={partyResourceName} link="show">
                {component}
            </ReferenceField>
            }
        </span>
    )
}

PartyField.propTypes = {
    label: PropTypes.string,
    source: PropTypes.string.isRequired,
    partyTypeSource: PropTypes.string,
}
