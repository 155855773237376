import PropTypes from 'prop-types'
import React from "react";
import {ReferenceInput} from "react-admin";
import SelectInputWithChoiceContext from "./SelectInputWithChoiceContext";


export default function DictInput(props) {
    return (
        <ReferenceInput name={props.name} link={""} label={props.label} filter={props.filter} source={props.source}
                        sort={{field: "sortOrder", order: "ASC"}}
                        reference={props.reference} alwaysOn={props.alwaysOn}>
            <SelectInputWithChoiceContext
                label={props.label} onChange={props.onChange} optionValue={props.optionValue ? props.optionValue : "code"}
                optionText={props.optionText ? props.optionText : "name"}
                fullWidth={props.fullWidth} required={props.required} disabled={props.disabled}
                alwaysOn={props.alwaysOn} resettable={props.resettable}
                sx={props.sx}
            />
        </ReferenceInput>
    );
}

DictInput.propTypes = {
    filter: PropTypes.object,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    reference: PropTypes.string.isRequired,
    required: PropTypes.bool,
    source: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    alwaysOn: PropTypes.bool,
    resettable: PropTypes.bool,
    optionText: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.element]),
    optionValue: PropTypes.string,
    sx: PropTypes.object,
}
