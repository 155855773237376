import PropTypes from 'prop-types'
import {AutocompleteInput, ReferenceInput} from "react-admin";
import Resources from "../../Resources";
import React from "react";

function LegalEntityInput(props) {
    const getManyResource = Resources.LEGAL_ENTITY.name + "/" + Resources.LEGAL_ENTITY.actions.FIND_BY_IDS.name;
    const resource = props.resource || Resources.LEGAL_ENTITY.name

    console.log("LEI", resource, props.resource)
    const defineMeta = () => {
        let meta = {getManyResource}
        if (props.filter) {
            meta.method = 'post'
        }
        return meta;
    }
    const meta = defineMeta()
    const formatter = (value) => {
        if (value) {
            return value.id + ": " + value.fullCompanyName;
        }
        return 'N/A'
    }
    const optionText = props.optionText || formatter

    return (
        <ReferenceInput source={props.source} enableGetChoices={({name}) => {return name && name.length > 2}}
                        reference={resource}
                        isRequired={props.isRequired} queryOptions={{meta}}>
            <AutocompleteInput label={props.label} optionValue="id" onChange={props.onChange} isRequired={props.isRequired}
                               filterToQuery={(searchText) => {return {...{name: searchText}, ...props.filter}}}
                               fullWidth={props.fullWidth} optionText={optionText} sx={props.sx}
                               alwaysOn={props.alwaysOn}
            />
        </ReferenceInput>
    );
}

export default LegalEntityInput;

LegalEntityInput.propTypes = {
  fullWidth: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  source: PropTypes.string.isRequired,
  filter: PropTypes.object,
  resource: PropTypes.string,
  optionText: PropTypes.string || PropTypes.func || PropTypes.element,
  sx: PropTypes.object,
  alwaysOn: PropTypes.bool,
}