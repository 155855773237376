import PropTypes from 'prop-types'
import React from "react";
import SelectInputWithChoiceContext from "./SelectInputWithChoiceContext";

export default function EnumDictInput(props) {
    return (
            <SelectInputWithChoiceContext name={props.name} label={props.label} source={props.source} choices={Object.entries(props.enum).map(([k, v]) => v)}
                                          onChange={props.onChange} optionValue="code" optionText="label"
                                          fullWidth={props.fullWidth} required={props.required} disabled={props.disabled}
                                          multipleChoice={props.multipleChoice}
                                          alwaysOn={props.alwaysOn} resettable={props.resettable}
                                          sx={props.sx}
            />
    );
}

EnumDictInput.propTypes = {
  disabled: PropTypes.bool,
  enum: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  multipleChoice: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  source: PropTypes.string.isRequired,
  alwaysOn: PropTypes.bool,
  resettable: PropTypes.bool,
  sx: PropTypes.object,
}
